export const columns = [
	{
		title: "商品状态",
		width: "7%",
		dataIndex: "status",
		customRender: function (status) {
			switch (status) {
			case 2: return '已下架'
			case 1: return '上架中'
			default: break;
			}
		}
	},
	{
		title: "推送状态",
		width: "7%",
		dataIndex: "appShopPush",
		customRender: function (appShopPush) {
			switch (appShopPush) {
			case false: return '未推送'
			case true: return '已推送'
			default: break;
			}
		}
	},
	{
		title: "编号",
		width: "16%",
		dataIndex: "code",
	},
	{
		title: "商品图片",
		width: "7%",
		dataIndex: "imgList",
		scopedSlots: { customRender: "mainPhoto"}
	},
	{
		title: "商品名称",
		width: "10%",
		dataIndex: "name",
	},
	{
		title: "商品类型",
		width: "12%",
		dataIndex: "categoryNamePath",
	},
	{
		title: "库存",
		width: "6%",
		dataIndex: "stock",
	},
	{
		title: "销量",
		width: "6%",
		dataIndex: "salesVolume",
	},
	{
		title: "更新时间",
		width: "10%",
		dataIndex: "updatedAt",
	},
	{
		title: "操作",
		dataIndex: "action",
		key: "action",
		width: "250",
		fixed: "right",
		scopedSlots: { customRender: "action" },
	},
]
export const formItem = [
	{
		type: 'input',
		label:'sku编码',
		prop:'skuId',
		placeholder:'请输入'
	},
	{
		type: 'input',
		label:'商品名称',
		prop:'skuName',
		placeholder:'请输入'
	},
	// {
	// 	type: 'select',
	// 	label:'上架状态',
	// 	prop:'status',
	// 	placeholder:'请选择',
	// 	option:[{ id: false,name:'下架'},{ id: true,name:'上架'}],
	// },
]
export const pagination = {
	current: 1,
	total: 0,
	pageSize: 10,
	showTotal: (total) => `共 ${total} 条`,
	pageSizeOptions: ['10','50','100','200'],
	showSizeChanger: true,
	showQuickJumper: true,
}